html {
    /* src: ("./fonts/BAHNSCHRIFT.TTF") format(truetype); */
    src: ("./fonts/Roboto/Roboto-Regular.ttf") format(truetype);
    font-weight: bold;
    background-color: white;
    color: #7c7c7c;
}
body {
    overflow-x: hidden;
    /* src: ("./fonts/BAHNSCHRIFT.TTF") format(truetype); */
    src: ("./fonts/Roboto/Roboto-Regular.ttf") format(truetype);
    font-weight: bold;
    background-color: white;
    color: #7c7c7c;
}

.content {
    padding-top: 1vh;
    padding-bottom: 10vh;
    min-width: 400px;
}
.box {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
}
.fill {
    height: 100%;
}

.box-top {
    margin-top: 0;
}

.row {
    margin: 10px;
    margin-top: 0px;
}
.to-left {
    margin-right: auto;
}
.to-right {
    margin-left: auto;
}

.btn {
    font-size: 1rem;
    font-weight: bold;
    height: 2.5rem;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
    color: white;
    height: auto;
}
.btn-block {
    width: 100%;
}
.btn-red {
    background: #e42c2a;
    border-color: #e42c2a;
    color: white;
}

.btn-red:hover {
    background: #7a1616;
    border-color: #7a1616;
    color: white;
}
.btn-yellow {
    background: #f8e73f;
    border-color: #f8e73f;
    color: #707070;
}
.btn-yellow:hover {
    background: #c4b531;
    border-color: #c4b531;
    color: #707070;
}
@media (max-width: 768px) {
    .fill {
        height: auto;
    }
    .box-top {
        margin-top: 10px;
    }
    .row {
        margin-bottom: 0px;
    }
}

/* TABELAS */
table {
    text-align: center;
}
thead {
    color: #7c7c7c;    
    font-size: 0.8rem;
}
tbody {
    font-weight: 100;
    font-size: 0.8rem;
}
tbody svg {
    fill: #e42c2a;
    width: 26px;
    height: 26px;
}

.link { 
    color: #7c7c7c;
}
.link:visited {
    color: #7c7c7c;
}

.modal-title {
    width: 100%;
    text-align: center;
}
.options-info-modal .boleto-disclaimer {
    width: 80%;
    margin: auto;
    text-align: center;
    margin-bottom: 25px;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 8px 16px;
}
.options-info-modal .info-box ul li {
    list-style: none;
    margin: 6px 0;
}

.options-info-modal .info-box ul li .value{
    font-weight: 200;
    color: black
}

.options-file-modal {
    max-width: 90%;
    height: 100%;    
    margin-left: 5%;
}

.options-file-modal .modal-content{    
    height: 90%;    
}

@media(max-width: 768px) {
    .modal-footer {
        display: flex;
        justify-content: center;
    }
}

.comingo-soon {
    font-size:30px;
    font-weight: 300;
    line-height: 20vh;
    width: 100vw;
    text-align: center;
}

.nav-item {
    cursor: pointer;
    font-weight: 300;
}
.nav-item .nav-link.active {
    font-weight: 700;
    color: #7c7c7c;
}

.disclaimer-font {
    font-size: 0.75rem;
    font-weight: 500;
}