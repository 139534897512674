.row{
    margin: auto !important;
    margin-bottom: 10px !important;
}

p.step-title{
    color: #000;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

p.step-title-gray{
    color: #6D6E71;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step-container.contract-details p{
    color: #6D6E71;
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.alert-danger{
    border-radius: 8px !important;
    background: #FEE !important;

    color: #B25F5F;
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    /* margin-left: 12px; */
    width: 100%;
    /* max-width: 390px; */
    max-width: 570px;
}

.alert-danger h4{
    color: #982F2F;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 19px;
}

.binds-content-widget{
    pointer-events: none;
}

.binds-content-widget .binds-custom-action-icon{
    pointer-events: all;
}

.binds-content-widget .close-binds-action{
    pointer-events: all;
}

.binds-content-widget .binds-animated{
    pointer-events: all;
}

.form-control{
    appearance: auto !important;
    cursor: pointer;
}