.agendamentos-container{
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 24px 27px;
    margin: 0;
    width: 100%;
    /* max-width: 489px; */
    height: 100%;
    border-radius: 7px;
    background: #FFF;
    overflow: auto;
}

.header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    padding-bottom: 19px;
    border-bottom: 1px solid #E4E4E4;
    margin-bottom: 26px;
}

.year-selector{
    color: #6D6E71;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: #f5f5f5;
    cursor: pointer;
    /* appearance: none; */
    border: 0;
    outline: 0;
    background: none;
    color: inherit;
    box-shadow: none;
}

.month-change-control{
    cursor: pointer;
}

.date-container{
    text-align: center;
}

.day-of-month-container{
    text-align: center;
    margin: auto;   
}

.arrow-container img{
    margin: auto;
    cursor: pointer;
}

.month-control-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-right: 10px;
    width: 100%;
    height: 100%;
    background: #FFF;
    color: #6D6E71;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.month-name-big{
    margin-right: 5px;
    margin-left: 5px;
    min-width: 110px;
}

.title-arrow-container{
    border-radius: 10px;
    background: #F2F2F2;
    padding: 18px 23px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 8px;
    cursor: pointer;
}

.body-container{
    padding-bottom: 26px;
    padding-right: 14px;
    /* height: 237px; */
    height: 272px;
    overflow-y: auto;
    overflow-x: visible;
}

.appointment-date-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: #FFF;
    margin-bottom: 8px;
}

.body-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #FFF;
}
   
  .body-container::-webkit-scrollbar
  {
      width: 6px;
      background-color: #FFF;
  }
   
  .body-container::-webkit-scrollbar-thumb
  {
      border-radius: 100px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #D9D9D9;
  }